import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { H1 } from "../../components/Type";
import { Container, Section } from "../../components/LayoutUtils";
import ClothSuppliers from "../../components/ClothSuppliers";

const ClothsPage = () => (
  <Layout>
    <SEO
      title="Our cloths"
      description="The two special ingredients of a Bespoke suit are the cut and then the cloth. We stock hundreds of the very best wool, linen and cotton fabrics."
    />
    <Container>
      <Section>
        <H1>Our cloth suppliers</H1>
        <ClothSuppliers />
      </Section>
    </Container>
  </Layout>
);

export const query = graphql`
  {
    ...allContentfulClothSuppliers
  }
`;

export default ClothsPage;
