import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { map, pipe, get } from "lodash/fp";
import ClothSuppliers from "./ClothSuppliers";
import { processResult } from "../GalleryItemList/container";

const query = graphql`
  {
    ...allContentfulClothSuppliers
    ...allGalleryItems
  }
`;

const render = props => data => {
  const clothSuppliers = pipe(
    get("clothSuppliers.edges"),
    map("node"),
    map(supplier => {
      const images = processResult(supplier.name)(data);
      return { images, ...supplier };
    })
  )(data);
  return <ClothSuppliers clothSuppliers={clothSuppliers} {...props} />;
};

const ProcessStepsContainer = props => (
  <StaticQuery query={query} render={render(props)} />
);

export default ProcessStepsContainer;
