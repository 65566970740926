import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash/fp";
import Image from "gatsby-image";
import classnames from "classnames";

import { LinkButton } from "../Button";
import { H3 } from "../Type";
import styles from "./styles.module.scss";

const Supplier = ({ name, logo, url, images }) => (
  <div className={classnames("col-md-4", styles.supplier)}>
    <div className={styles.supplierCard}>
      <H3>{name}</H3>
      <div className={styles.supplierLogo}>
        <Image
          {...logo}
          title={name}
          style={{ top: "50%", transform: " translateY(-50%)" }}
        />
      </div>
      <div className={styles.links}>
        <LinkButton target="_blank" className={styles.linkBtn} href={url}>
          View site
        </LinkButton>
        <LinkButton
          disabled={images.length === 0}
          className={styles.linkBtnSecondary}
          href={`/gallery/?tag=${name}`}
        >
          <span>View gallery</span>{" "}
          <span
            className={classnames(styles.imageCount, {
              [styles.imageCountDisabled]: images.length === 0
            })}
          >
            {images.length}
          </span>
        </LinkButton>
      </div>
    </div>
  </div>
);

Supplier.propTypes = {
  name: PropTypes.string.isRequired,
  logo: Image.propTypes.isRequired,
  url: PropTypes.string.isRequired
};

const ClothSuppliers = ({ clothSuppliers = [] }) => (
  <div className={styles.suppliers}>
    <div className="container">
      <div className="row">
        {map(supplier => <Supplier {...supplier} />)(clothSuppliers)}
      </div>
    </div>
  </div>
);

ClothSuppliers.propTypes = {
  clothSuppliers: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    logo: Image.propTypes.isRequired
  }).isRequired
};

export default ClothSuppliers;
